declare global {
  interface Window { amplitude: any; }
}

// import { useRouter } from 'next/router'
// import { useEffect, useState } from 'react'
// import NoSSR from 'react-no-ssr'
// import TwoCirclesSpinner from 'components/Spinner/TwoCirclesSpinner'
import Headline from './Headline'
// import Headline2 from './Headline2'
// import Headline3 from './Headline3'
// import Headline4 from './Headline4'
import * as styles from './headline.module.css'
// import { DEFAULT_OCCASION_LABEL, DEFAULT_PERSON_LABEL } from 'config/cardOptions'

// interface HeadlineProps {
//   occasion?: string,
//   someoneYouLove?: string,
// }

const ExperimentHeadline = () => {
// const ExperimentHeadline = ({ occasion = DEFAULT_OCCASION_LABEL, someoneYouLove = DEFAULT_PERSON_LABEL }: HeadlineProps) => {
  // const router = useRouter()

  // const cohortContent: { [ id: number ]:JSX.Element } = {
  //   1: <Headline />,
  //   // 2: <Headline2 />,
  //   // 3: <Headline3 />,
  //   // 4: <Headline4 />,
  // }
  // const numberOfExperimentOptions = Object.keys(cohortContent).length
  // const getRandomCohort = () => Math.floor(Math.random() * numberOfExperimentOptions) + 1

  // const getCohort = () => {
  //   if (typeof window === 'undefined') {
  //     return undefined
  //   }
  //   const key = 'headlineCohort'
  //   const paramValue = router.query[key]
  //   // const cookieValue = cookies[key]
  //   const localStorageNumber = Number(window.localStorage.getItem(key))
  //   const cohortNumber = Number(paramValue) || localStorageNumber || getRandomCohort()
  //   console.log({ localStorageNumber, cohortNumber })
  //   if (localStorageNumber !== cohortNumber) {
  //     recordCohort(cohortNumber)
  //     window.localStorage.setItem(key, cohortNumber.toString())
  //   }
  //   return cohortNumber
  // }

  // const recordCohort = (cohort: Number) => {
  //   if (window?.amplitude) {
  //     window.amplitude.getInstance().logEvent(`Headline Experiment: Option ${cohort} loaded`)
  //   } else {
  //     setTimeout(recordCohort, 1000)
  //   }
  // }

  // const [cohort, setCohort] = useState<number | undefined>(undefined)
  // useEffect(() => {
  //   const cohort = getCohort()
  //   cohort && setCohort(cohort)
  // }, [])
  return <div className={styles.hero}>
    <Headline />

  </div>
  // return <NoSSR>
  //   { cohort && Number.isInteger(cohort)
  //     ? <div className={styles.hero}>
  //       <Headline />
  //       {/* { cohortContent[cohort] } */}
  //       <div className={styles.body}>
  //         <p>CardHero finds a better { occasion } card for { someoneYouLove }, and delivers it to your mailbox before you need&nbsp;it.</p>
  //         <p><strong>Just $6.99</strong> and <strong>free shipping</strong> anywhere in the&nbsp;US.</p>
  //         <p>Satisfaction guaranteed or your money&nbsp;back.</p>
  //       </div>
  //     </div>
  //     : <TwoCirclesSpinner />
  //   }
  // </NoSSR>
}

export default ExperimentHeadline




// import { useRouter } from 'next/router'
// import { useEffect, useState } from 'react'
// import { parseCookies, setCookie } from 'nookies'
// import NoSSR from 'react-no-ssr'
// import TwoCirclesSpinner from 'components/Spinner/TwoCirclesSpinner'
// import Headline from './Headline'
// import Headline2 from './Headline2'
// import Headline3 from './Headline3'
// import Headline4 from './Headline4'

// const cohortContent = {
//   1: <Headline />,
//   2: <Headline2 />,
//   // 3: <Headline3 />,
//   // 4: <Headline4 />,
// }
// const numberOfExperimentOptions = Object.keys(cohortContent).length
// const getRandomCohort = () => Math.floor(Math.random() * numberOfExperimentOptions) + 1

// const ExperimentHeadline = ({ cookies }) => {
//   const router = useRouter()

//   const checkForAmplitude = () => {
//     if (window.amplitude) {
//       window.amplitude.getInstance().logEvent(`Headline Experiment: Option ${cohort} loaded`)
//     } else {
//       setTimeout(checkForAmplitude, 1000)
//     }
//   }

//   const [cohort, setCohort] = useState(undefined)
//   useEffect(() => {
//     checkForAmplitude()
//     setCohort(cookies.headlineCohort)
//   }, [])
//   return <NoSSR>
//     { Number.isInteger(cohort)
//       ? cohortContent[cohort]
//       : <TwoCirclesSpinner />
//     }
//   </NoSSR>
// }

// ExperimentHeadline.getServerSideProps = async (ctx) => {
//   console.log('EXPERIMENT HEADLINE GET SERVER SIDE PROPS')
//   const key = 'headlineCohort'
//   const cookies = parseCookies(ctx)

//   const paramValue = router.query[key]
//   const cookieValue = cookies[key]
//   const cohortNumber = Number(paramValue) || Number(cookieValue) || getRandomCohort()

//   // Set
//   setCookie(ctx, key, cohortNumber, {
//     maxAge: 30 * 24 * 60 * 60,
//     path: '/',
//   })

//   return { cookies }
// }

// export default ExperimentHeadline
