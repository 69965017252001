import styles from './landingPage.module.css'
import Headline from 'components/experiments/HeadlineExperiment'
import LandingCTA from 'components/LandingCTA'
import LandingSection from 'components/LandingSection'
import HowItWorks from 'components/experiments/HowItWorks'
import Page from '../Page'
import SellingPoints from 'components/SellingPoints'
import LandingBody from 'components/LandingBody'
import LandingCallouts from 'components/LandingCallouts'
import UntilHasScrolled from 'components/UntilHasScrolled'
import Icon from 'components/Icons/Icon'

interface LandingPageProps {
  occasion?: string,
}

const LandingPage = ({ occasion }: LandingPageProps) => {
  return <Page
    title='All the birthday, anniversary and other cards you need for your spouse delivered to your mailbox'
    titleAnalytics='Homepage'
    width='homepage'
    bodyContent={
      <>
        <LandingSection>
          <HowItWorks />
        </LandingSection>
        <LandingSection isPadded>
          <div className={styles.ctaMessaging}>
            <LandingCTA buttonText='More smiles, less chores&nbsp;&raquo;' />
          </div>
        </LandingSection>
      </>
    }
    styles={styles}
  >
    <UntilHasScrolled>
      <div className={styles.downArrowContainer}>
        <div className={styles.downArrowLabel}>Learn more</div>
        <Icon icon='rightArrow' className={styles.downArrow} />
      </div>
    </UntilHasScrolled>
    <div className={styles.landingHero}>
      <div className={styles.headline}>
        <Headline />
      </div>
      <div className={styles.landingBody}>
        <LandingBody occasion={occasion} />
        <div className={styles.ctaMessaging}>
          <LandingCTA />
        </div>
        <LandingCallouts />
        <SellingPoints />
        <div className={styles.ctaMessaging}>
          <LandingCTA buttonText='It only takes moments&nbsp;&raquo;' />
        </div>
      </div>
    </div>
  </Page>
}

export default LandingPage
