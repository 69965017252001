import { useEffect, useState } from 'react'
import styles from './untilHasScrolled.module.css'
import { throttle } from 'lodash'

type Props = {
  children: React.ReactNode,
}

const UntilHasScrolled = ({ children }: Props) => {
  const [isWindowScrolled, setIsWindowScrolled] = useState(false)
  const handleScroll = throttle(() => {
    setIsWindowScrolled(window.scrollY > 0)
  }, 500)
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])
  return <div className={ isWindowScrolled ? styles.hasScrolled : styles.hasNotScrolled }>
    { children }
  </div>
}

export default UntilHasScrolled
