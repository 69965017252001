import Icon from 'components/Icons/Icon'
import styles from './landingCallouts.module.css'

const LandingCallouts = () =>
  <div className={styles.callouts}>
    <div className={styles.callout}>
      <div className={styles.calloutIcon}>
        <div className={styles.freeShippingIcon}>
          <div className={styles.iconDollarSign}>$</div>
          <div className={styles.iconZero}>0</div>
        </div>
      </div>
      <div className={styles.calloutLabel}>FREE Shipping</div>
    </div>
    <div className={styles.callout}>
      <div className={styles.calloutIcon}>
        <Icon icon='runner' />
        <div className={styles.slashIcon}>
          <div className={styles.slash} />
        </div>
      </div>
      <div className={styles.calloutLabel}>No Running To&nbsp;Store</div>
    </div>
    <div className={styles.callout}>
      <div className={styles.calloutIcon}>
        <div className={styles.iconReasonablePrice}>
          <div className={styles.iconDollarSign}>$</div>
          <div className={styles.iconDollarValue}>4</div>
          <div className={styles.iconCentsValue}>.99+</div>
        </div>
      </div>
      <div className={styles.calloutLabel}>Reasonable Pricing</div>
    </div>
  </div>

export default LandingCallouts
